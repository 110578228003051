import type { MarginKey } from '../types/storyblok'

export const margins: Record<MarginKey, string | number> = {
  '': '',
  none: 'mt-0',
  xs: 'mt-2',
  sm: 'mt-4',
  md: 'mt-8',
  lg: 'mt-12',
  xl: 'mt-16',
  '2xl': 'mt-20',
  '3xl': 'mt-24',
  '4xl': 'mt-28',
}

const isMarginKey = (input: any): input is MarginKey =>
  typeof input === 'string' && Object.keys(margins).includes(input)

export const useStoryblokMargins = (
  content: Partial<{ margin_top: string | number }>,
) => {
  const key = isMarginKey(content?.margin_top) ? content.margin_top : ''
  const marginTop = computed(() => margins[key])
  const marginClasses = computed(() =>
    marginTop.value ? [marginTop.value] : [''],
  )

  const containerClasses = computed(() => {
    // @ts-expect-error
    return { container: Boolean(content.is_containered) }
  })

  return {
    marginTop,
    marginClasses,
    margins,
    containerClasses,
  }
}

export default useStoryblokMargins
